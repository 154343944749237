import React, { useState, useEffect } from 'react';
import queryString from 'query-string';

import Layout from '../layouts';
import Container from '../components/Container';

import '../components/ProductCategory/apply.css';

import loadComponents from '../components/Loadable';
import Spinner from '../components/Placeholder/spinner';

const SubpageHeader = loadComponents('subpage-header');
const Alert = loadComponents('alert');
const Download = loadComponents('download');

export default function DownloadPage({ location }) {
  const page = {
    name: 'Download your documents',
    title: `Download your documents`,
    slug: 'download',
  };
  const prevPage = location.state && location.state.prevPage;

  const [order, setOrder] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query && query.id) {
      setOrder(query.id);
    } else {
      setError(true);
    }
  }, []);

  return (
    <Layout title={page.name} prevPage={prevPage} padding="0 0 5.625rem">
      <SubpageHeader title={page.title} />
      <Container>
        {error && (
          <Alert error>
            Order number not found. Please try clicking the link that was sent
            to you in an email.
          </Alert>
        )}
        {!order ? (
          <div style={{ display: `flex` }}>
            <Spinner size="30px" />
            <p style={{ marginLeft: `45px` }}>Loading...</p>
          </div>
        ) : (
          <Download order={order} />
        )}
      </Container>
    </Layout>
  );
}
